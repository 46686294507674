import ReactDOM from "react-dom/client";

import App from "@/App";

// Init the App
import "@/init";

// Render the App
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render((<App />));
